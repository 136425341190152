// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { loginRoute } from 'App/routes';

const AppLink = ({ to, children }: { to: string, children: React.Node }) => (
  <Link to={to} className="text-blue hover:text-blue-dark no-underline">
    {children}
  </Link>
);

AppLink.Login = (props: { children: React.Node }) => (
  <AppLink to={loginRoute}>{props.children}</AppLink>
);

export default AppLink;
