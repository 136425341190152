// @flow
import * as React from 'react';
import Logo from './Logo';
import './Page.css';

type PageProps = {
  title?: string,
  children?: React.Node,
};

export default class Page extends React.Component<PageProps, void> {
  documentTitleOnInit = document.title;

  componentDidMount() {
    if (this.props.title) {
      document.title = `${this.props.title} | Powerreviews`;
    }
  }

  componentWillUnmount() {
    document.title = this.documentTitleOnInit;
  }

  render() {
    return (
      <div className="flex flex-col h-screen">
        <header className="flex-none bg-gray-darkest">
          <Logo className="block h-10 w-10 my-2 mx-auto" />
        </header>
        <div className="h-16" />
        <main className="flex items-start justify-center">
          <div className="block w-full mx-3 mb-8 Page">
            {this.props.children}
          </div>
        </main>
      </div>
    );
  }
}
