import fetch from 'App/lib/pwr-fetch';

type LoginProps = {
  username: string,
  password: string,
};

export const login = (props: LoginProps) =>
  fetch('/api/auth/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(props),
  });

export type MfaLoginProps = LoginProps & {
  deviceId: string,
  otp: string,
  skip: boolean,
};

export const mfaLogin = (props: MfaLoginProps) =>
  fetch('/api/auth/user/login/mfa', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(props),
  });

export const processSAML = (SAMLResponse: string) =>
  fetch(`/api/auth/user/process_saml?SAMLResponse=${SAMLResponse}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
