// @flow
import * as React from "react";

const Logo = (props: *) => (
  <svg viewBox="0 0 215 213.4" {...props}>
    {" "}
    <metadata id="metadata8">image/svg+xml</metadata>{" "}
    <defs>
      {" "}
      <clipPath id="clipPath18">
        {" "}
        <path id="path20" d="m0,0l4323.6,0l0,1707l-4323.6,0l0,-1707z" />{" "}
      </clipPath>{" "}
    </defs>{" "}
    <g>
      {" "}
      <title>Layer 1</title>{" "}
      <g id="g10" transform="matrix(1.25,0,0,-1.25,0,213.3875) ">
        {" "}
        <g id="g12" transform="scale(0.10000000149011612) ">
          {" "}
          <g id="g14">
            {" "}
            <g id="g16" clipPath="url(#clipPath18)">
              {" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path22"
                d="m902.1,1184.6l-190.8,0l0,-289.8l190.8,0c91.7,0 154,52.6 154,144.3c0,91.7 -62.3,145.5 -154,145.5zm7.3,-409.6l-198.1,0l0,-342.3l-132,0c0,0 0,770.6 0,804.7c0,34.1 32.3,65.8 67.7,65.8c35.4,0 262.4,0 262.4,0c170,0 278.8,-112.5 278.8,-264.1c0,-151.6 -108.8,-264.1 -278.8,-264.1"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path24"
                d="m1206,432.6l-200.3,288.2c0,0 -22.3,-10.7 -89.9,-10.7c-39.3,0 -65.1,0 -65.1,0l190.9,-277.6l164.3,0.2"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path26"
                d="m2137,1116.6c42,0 69.8,20.2 69.8,60.5c0,45.8 -30.5,60.5 -70.9,60.5l-75.8,0l0,-121l76.9,0zm-105.5,182.1c25.4,0 114.2,0 114.2,0c75.2,0 131.9,-42.5 131.9,-122.1c0,-59.4 -39.8,-121 -128.1,-121l-89.4,0l0,-145.6l-72.5,0c0,0 0,323.8 0,347.9c0,24 18.5,40.9 43.9,40.9"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path28"
                d="m2560.1,1043l0,124.3c0,46.9 -40.4,73.6 -86.2,73.6c-37.1,0 -83.4,-19.6 -83.4,-75.2l0,-124.3c0,-46.9 40.4,-73.6 86.1,-73.6c37.1,0 83.5,19.6 83.5,75.2zm-241.6,-5.4l0,124.3c0,92.1 78.5,143.4 155.4,143.4c84.5,0 158.1,-55.1 158.1,-141.8l0,-116.7c0,-92.2 -78.5,-143.4 -155.4,-143.4c-84.5,0 -158.1,47.4 -158.1,134.1"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path30"
                d="m2661.6,1298.7l74.2,0l61,-272.1l1.1,0l71.5,272.1l61.6,0l70.9,-273.7l1.1,0l62.2,273.7l73.1,0l-100.9,-390.4l-67,0l-69.8,274.3l-1.1,0l-69.8,-274.3l-67.6,0l-100.3,390.4"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path32"
                d="m3230.8,1298.7c20.9,0 231.2,0 231.2,0l0,-63.8l-197.4,0l0,-94.9l175.1,0c0,0 0,-9.8 0,-28.1c0,-18.3 -14.2,-35.7 -32.5,-35.7c-18.3,0 -142.5,0 -142.5,0l0,-102.5l199.6,0l0,-63.8l-272.1,0c0,0 0,332.6 0,351.6c0,18.9 17.7,37.2 38.7,37.2"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path34"
                d="m3675.3,1116.6c42,0 69.8,20.2 69.8,60.5c0,45.8 -30.6,60.5 -70.9,60.5l-71.4,0l0,-121l72.5,0zm-112.6,182.1c22.9,0 121.3,0 121.3,0c73.1,0 132,-42.5 132,-122.1c0,-46.3 -24.5,-94.3 -76.9,-112.9l96.5,-153.8l-79,0l-91.1,145.6l-62.7,0l0,-145.6l-72.5,0c0,0 0,334.6 0,354.9c0,20.2 11.5,33.9 32.5,33.9"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path36"
                d="m2132.6,654c42,0 69.8,20.2 69.8,60.5c0,45.8 -30.5,60.5 -70.9,60.5l-71.4,0l0,-121l72.5,0zm-111.7,182.1c20.9,0 120.5,0 120.5,0c73.1,0 132,-42.5 132,-122.1c0,-46.3 -24.5,-94.3 -76.9,-112.9l96.5,-153.8l-79,0l-91.1,145.6l-62.7,0l0,-145.6l-72.5,0c0,0 0,337.2 0,356.1c0,18.9 12.4,32.7 33.3,32.7"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path38"
                d="m2375.6,836.1c19,0 234.1,0 234.1,0l0,-63.8l-197.4,0l0,-94.9l175,0c0,0 0,-12.5 0,-30.8c0,-18.3 -12.5,-33 -30.8,-33c-18.3,0 -144.2,0 -144.2,0l0,-102.5l199.6,0l0,-63.8l-272.1,0c0,0 0,337.1 0,355.4c0,18.3 16.8,33.3 35.8,33.3"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path40"
                d="m2671.4,836.1l78.5,0l87.8,-286.3l1.1,0l87.8,286.3l72,0l-128.7,-390.4l-70.9,0l-127.6,390.4"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path42"
                d="m3104.7,836.1c21.6,0 35.6,0 35.6,0l0,-388.8l-72.5,0c0,0 0,330.6 0,353.5c0,22.9 15.3,35.3 36.9,35.3"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path44"
                d="m3262.2,836.1c22.2,0 234.8,0 234.8,0l0,-63.8l-197.4,0l0,-94.9l175,0c0,0 0,-12.5 0,-30.8c0,-18.3 -10.5,-33 -31.4,-33c-20.9,0 -143.6,0 -143.6,0l0,-102.5l199.5,0l0,-63.8l-272.1,0c0,0 0,331.3 0,354.1c0,22.9 12.9,34.6 35.1,34.6"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path46"
                d="m3543.3,836.1l74.2,0l61,-272.1l1.1,0l71.4,272.1l61.6,0l70.9,-273.7l1.1,0l62.2,273.7l73,0l-100.9,-390.4l-67,0l-69.8,274.3l-1.1,0l-69.8,-274.3l-67.6,0l-100.3,390.4"
              />{" "}
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                id="path48"
                d="m4017.1,537.3l65.4,25.6c14.7,-37.6 50.2,-58.9 93.8,-58.9c39.2,0 76.3,15.3 76.3,51.2c0,28.9 -23.5,42 -53.4,50.7l-65.4,15.8c-51.8,13.1 -93.2,36.5 -93.2,105.2c0,73.1 63.8,115.6 139,115.6c64.9,0 111.8,-21.3 136.8,-79.6l-59.4,-26.2c-16.9,32.7 -45.8,44.7 -83.4,44.7c-31.6,0 -62.2,-14.2 -62.2,-49.1c0,-27.3 26.1,-39.8 56.7,-48l66.5,-16.3c46.4,-10.9 88.9,-38.7 88.9,-108c0,-71.4 -63.8,-118.8 -149.4,-118.8c-71.4,0 -136.9,30 -157.1,95.9"
              />{" "}
              <path
                fill="#79cfb3"
                fillRule="nonzero"
                id="path50"
                d="m127.3,850.4c-0.5,181.8 57.7,338.6 176.6,476.1l-96.3,83.3c-139.7,-161.7 -208.1,-346 -207.6,-559.7c1.2,-470.7 383.8,-851.3 854.4,-850.1l-0.3,127.2c-400.4,-1 -725.8,322.8 -726.8,723.2"
              />{" "}
              <path
                fill="#fab20b"
                fillRule="nonzero"
                id="path52"
                d="m1570.6,753.2c0,0 0,0 0,0c-49.7,-360.6 -352.4,-625.1 -716.4,-626c0,0 -0.1,0 -0.1,0l0.3,-127.2c427.8,1.1 783.6,311.9 842.1,735.8l-125.9,17.4"
              />{" "}
              <path
                fill="#69ceea"
                fillRule="nonzero"
                id="path54"
                d="m1302,1576.2l-67.1,-108c0,0 0.1,0 0.1,-0.1c247.3,-153.6 375.4,-426.5 335.6,-714.9l125.9,-17.4c46.8,339 -103.8,659.8 -394.5,840.4"
              />{" "}
              <path
                fill="#80cc28"
                fillRule="nonzero"
                id="path56"
                d="m1234.9,1468.1l67.1,108c-217.9,135.4 -455.9,166.1 -701,90.5l37.5,-121.6c0,0 0,0 0.1,0c208.5,64.3 411,38.2 596.3,-76.9"
              />{" "}
              <path
                fill="#f1491c"
                fillRule="nonzero"
                id="path58"
                d="m638.5,1545l-37.5,121.7c-163.2,-50.4 -281.6,-127.7 -393.4,-257l96.3,-83.3c0,0 0,0 0,0c95.1,109.9 195.7,175.7 334.6,218.5"
              />{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

export default Logo;
