import * as React from 'react';
import { Page, Card, P } from 'App/components';
import { Button } from 'App/screens/components';
import { Link } from 'react-router-dom';
import { loginRoute } from 'App/routes';

export const NewPasswordConfirmation = () => (
  <Page title="Password Created">
    <Card title="Password Created">
      <P>Your password has been set!</P>
      <P>You can now log in to your Powerreviews Account.</P>
      <hr className="border-b border-gray-lighter -mx-6" />
      <div className="mt-8 text-center">
        <Button kind="primary">
          {({ className }) => (
            <Link to={loginRoute} className={className}>
              Continue to Login
            </Link>
          )}
        </Button>
      </div>
    </Card>
  </Page>
);

export default NewPasswordConfirmation;
