// @flow
import { parse as parseUrl } from 'url';
import { type TwoFactorDevice } from './types';
import find from 'array.prototype.find';

export function redirectDestination(homeHref: string, continue_to: ?string) {
  if (typeof continue_to !== 'string') return homeHref;

  if (Boolean(continue_to.trim()) === false) return homeHref;

  const { hostname } = parseUrl(decodeURIComponent(continue_to));

  if (!hostname) return homeHref;

  if (hostname !== 'localhost' && /powerreviews/.test(hostname) === false)
    return homeHref;

  return continue_to;
}

export function redirect(dest: string) {
  window.location.replace(dest);
}

export type CompleteLoginResponse = {
  mfa: false,
  homeHref: string,
  token: string,
  expiration: number,
};
export type MFALoginResponse = {
  mfa: true,
  devices: Array<TwoFactorDevice>,
  optional: boolean,
  oneloginRedirect: ?string,
};
export type LoginResponse = CompleteLoginResponse | MFALoginResponse;

export async function processLoginResponse(
  response: Response
): Promise<LoginResponse> {
  const payload = await response.json();

  if (typeof payload.token === 'string') {
    const { apps, token, expiration } = payload;
    const home = find(apps, app => app.label === 'Home');

    return {
      mfa: false,
      token,
      expiration,
      homeHref: home.href,
    };
  } else {
    const { deviceIds: devices, optional, oneloginRedirect } = payload;

    return {
      mfa: true,
      devices: Array.isArray(devices) ? devices : [],
      optional: Boolean(optional),
      oneloginRedirect,
    };
  }
}
