// @flow
import Cookie from 'js-cookie';

const USER_SERVICE_TOKEN_NAME = 'user_service_token1';
const USER_SERVICE_TOKEN_DOMAIN =
  process.env.NODE_ENV === 'production' ? 'powerreviews.com' : '';

export function setCookie(token: string, secondsTillExpiration: number) {
  Cookie.set(USER_SERVICE_TOKEN_NAME, token, {
    domain: USER_SERVICE_TOKEN_DOMAIN,
    path: '/',
    // express seconds till expiration as a ratio of secs in a day
    expires: +secondsTillExpiration / (24 * 60 * 60),
    sameSite: 'strict',
    secure: process.env.REACT_APP_BUILD_ENV !== 'dev',
  });
}

export function clearCookie() {
  Cookie.remove(USER_SERVICE_TOKEN_NAME, {
    domain: USER_SERVICE_TOKEN_DOMAIN,
    path: '/',
  });
}
