// @flow
// TODO Finalize icons
import * as React from 'react';
import './status-icons.css';

export const InfoIcon = () => (
  <svg
    viewBox="0 0 10 10"
    className="mr-2  fill-current text-gray h-2 w-2 status-icons"
  >
    <circle cx="5" cy="5" r="5" stroke="black" strokeWidth="0" />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    viewBox="0 0 10 10"
    className="fill-current text-green h-2 w-2 status-icons"
  >
    <circle cx="5" cy="5" r="5" stroke="black" strokeWidth="0" />
  </svg>
);

export const ErrorIcon = () => (
  <svg
    viewBox="0 0 10 10"
    className="fill-current text-red h-2 w-2 status-icons"
  >
    <circle cx="5" cy="5" r="5" stroke="black" strokeWidth="0" />
  </svg>
);
