// @flow
import * as React from 'react';
import { type ContextRouter, Link } from 'react-router-dom';
import { Page, Card, P, Button } from 'App/components';
import { loginRoute } from 'App/routes';
import { parse as parseUrl } from 'url';
import { processSAML } from './api';

type Props = ContextRouter;

export default class TwoFactorDeviceRegistrationComplete extends React.Component<
  Props
> {
  async componentDidMount() {
    const { query: { SAMLResponse } = {} } = parseUrl(
      this.props.location.search,
      true
    );

    try {
      await processSAML(SAMLResponse);
    } catch (err) {}
  }

  render() {
    return (
      <Page title="Registration Complete">
        <Card title="Registration Complete">
          <P>
            Your two factor authentication device has been successfully
            registered. You may now log in.
          </P>
          <Card.Divider />
          <div className="mt-8 text-center">
            <Button kind="primary">
              {({ className }) => (
                <Link to={loginRoute} className={className}>
                  Continue to Log in
                </Link>
              )}
            </Button>
          </div>
        </Card>
      </Page>
    );
  }
}
