// @flow
import Errors, { NetworkingError, ClientError, ServerError } from './Errors';

function isJsonResponse(response: Response): boolean {
  const contentType = response.headers.get('content-type');

  return contentType != null && contentType.indexOf('application/json') !== -1;
}

async function checkIfResponseIsOK(response: Response): * {
  if (response.ok) return response;

  const { status, statusText } = response;

  // ServerErrors
  if (response.status >= 500) {
    return Promise.reject(new ServerError(statusText, status));
  }

  // Client Errors
  if (isJsonResponse(response)) {
    try {
      const body = await response.json();

      return Promise.reject(ClientError.createFromObject(body, status));
    } catch (err) {}
  }

  return Promise.reject(new ClientError(statusText, status));
}

const pwrFetch = (url: string, options: *) =>
  fetch(url, options)
    .then(checkIfResponseIsOK)
    .catch((err: Error) =>
      Promise.reject(Errors.isHttpError(err) ? err : new NetworkingError())
    );

export default pwrFetch;
