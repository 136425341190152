// @flow
import * as React from 'react';
import { parse as parseUrl } from 'url';

export type MFAProps = {
  username: string,
  password: string,
  oneloginRedirect: string,
  devices: Array<*>,
  optional: boolean,
};
export type AppStateShape = {
  continueTo: ?string,
  profileId?: number,
  workflow: null | 'ExpiredPassword' | 'MFA' | 'MFADeviceRegistration',
  mfaProps?: MFAProps,
};

const defaultAppState = {
  continueTo: null,
  workflow: null,
};

type AppContextType = {
  appState: AppStateShape,
  setAppState: (*) => void,
  setMFAWorkflow: (*) => void,
  setExpiredPasswordWorkflow: (*) => void,
  clearAppState: () => void,
};

const {
  Provider: ContextProvider,
  Consumer,
} = React.createContext<AppContextType>({
  appState: defaultAppState,
  setAppState: () => {},
  clearAppState: () => {},
  setMFAWorkflow: () => {},
  setExpiredPasswordWorkflow: () => {},
});

type AppProviderState = {
  isMounted: boolean,
} & AppContextType;

class Provider extends React.Component<
  { children: React.Node },
  AppProviderState
> {
  componentDidMount() {
    const { query: { continue_to } = {} } = parseUrl(
      window.location.href,
      true
    );

    window.sessionStorage.setItem('continue_to', continue_to || '');

    this.setState({
      isMounted: true,
      appState: {
        continueTo: continue_to,
        workflow: null,
      },
    });
  }

  setAppState = (props: mixed) => {
    this.setState(prevState => ({
      appState: {
        ...prevState.appState,
        ...props,
      },
    }));
  };

  setMFAWorkflow = (props: *) => {
    const workflow = 'MFA';
    this.setAppState({
      ...props,
      workflow,
    });
  };

  setExpiredPasswordWorkflow = (props: { profileId: number }) => {
    const workflow = 'ExpiredPassword';
    this.setAppState({
      ...props,
      workflow,
    });

    window.sessionStorage.removeItem('workflow');
  };

  clearAppState = () => {
    this.setState({ appState: defaultAppState });
    window.sessionStorage.removeItem('workflow');
    window.sessionStorage.removeItem('continue_to');
  };

  state = {
    isMounted: false,
    appState: defaultAppState,
    setAppState: this.setAppState,
    clearAppState: this.clearAppState,
    setMFAWorkflow: this.setMFAWorkflow,
    setExpiredPasswordWorkflow: this.setExpiredPasswordWorkflow,
  };

  render() {
    return (
      <ContextProvider value={this.state}>
        {this.state.isMounted ? this.props.children : null}
      </ContextProvider>
    );
  }
}

export default {
  Provider,
  StaticProvider: ContextProvider,
  Consumer,
};
