// @flow
import * as React from 'react';
import { HttpError } from 'App/lib/pwr-fetch';
import { ErrorAlert } from 'App/components';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from 'App/routes';

export const loginErrorMessage = (error: Error, attempts?: number) => {
  if (error instanceof HttpError) {
    if (error.status === 400) {
      return 'Email/Password required.';
    }

    if (error.status && error.status < 500) {
      const errorCode = error.message;

      if (errorCode === 'ExpiredPassword') {
        return 'Your password has expired';
      } else if (typeof attempts === 'number' && attempts > 3) {
        return (
          <>
            <ErrorAlert.Copy>
              You may have entered the wrong email or password, or you might be
              locked out of your account.
            </ErrorAlert.Copy>
            <ErrorAlert.Copy>
              If this issue persists, consider{' '}
              <ErrorAlert.Link>
                {({ className }) => (
                  <Link className={className} to={resetPasswordRoute}>
                    resetting your password
                  </Link>
                )}
              </ErrorAlert.Link>
              .
            </ErrorAlert.Copy>
          </>
        );
      }

      return 'Invalid email or password';
    }
  }

  return 'An error occured while logging in, try again';
};

type Props = {
  error: Error,
  attempts?: number,
};

const LoginError = (props: Props) => {
  const { error, attempts } = props;
  return <ErrorAlert>{loginErrorMessage(error, attempts)}</ErrorAlert>;
};

export default LoginError;
