// @flow
type Rule = {
  id: string,
  message: string,
  test: string => ?boolean,
};

const rules: Array<Rule> = [
  {
    id: 'password.length',
    message: 'Minimum of 8 characters',
    test: value => value.trim().length >= 8,
  },
  {
    id: 'password.case_sensitivity_and_digits--uppercase',
    message: 'At least ONE uppercase letter',
    test: value => /[A-Z]+/.test(value),
  },
  {
    id: 'password.case_sensitivity_and_digits--lowercase',
    message: 'At least ONE lowercase letter',
    test: value => /[a-z]+/.test(value),
  },
  {
    id: 'password.case_sensitivity_and_digits--digit',
    message: 'At least ONE number',
    test: value => /[0-9]/.test(value),
  },
];

type Result = {
  id: string,
  message: string,
  result: ?boolean,
};

export default function validator(
  value: string,
  bootstrapFails: Set<string> = new Set()
): Array<Result> {
  return rules.map(({ test, ...rule }) => {
    if (bootstrapFails.has(rule.id)) {
      return {
        ...rule,
        result: false,
      };
    }

    return {
      ...rule,
      result: test(value),
    };
  });
}
