// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};
export const ErrorAlert = ({ children }: Props) => (
  <div
    data-testid="error-alert"
    className="bg-red-lightest text-red-dark text-center text-sm font-normal mb-4 p-2 rounded-md"
  >
    {children}
  </div>
);

const ErrorCopy = ({ children }: Props) => (
  <p className="text-left m-2">{children}</p>
);

const ErrorLink = ({ children }: { children: (*) => React.Node }) => {
  return children({
    className:
      'hover:text-red-darker no-underline border-b border-red-dark hover:border-red-darker text-red-dark ',
  });
};

ErrorAlert.Copy = ErrorCopy;
ErrorAlert.Link = ErrorLink;
