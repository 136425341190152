// @flow
import * as React from 'react';

type Props = {
  label?: string,
  className?: string,
  type?: 'button' | 'submit' | 'reset',
  size?: string,
  kind?: 'primary' | 'secondary' | 'muted',
  children?: React.Node | (({ className: string }) => React.Node),
};

export const Button = (props: Props) => {
  const {
    className: propClassName = '',
    type = 'button',
    size = 'lg',
    label,
    children,
    kind = 'primary',
    ...rest
  } = props;

  const className = `btn btn--${kind} text-${size} ${propClassName}`;

  if (typeof children === 'function') {
    return children({
      className,
    });
  }

  return (
    <button type={type} className={className} {...rest}>
      {label || children}
    </button>
  );
};

export const ButtonPrimary = Button;

export const ButtonMuted = (props: *) => <Button kind="muted" {...props} />;

export const ButtonSecondary = (props: *) => (
  <Button kind="secondary" {...props} />
);
