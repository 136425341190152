// @flow
import * as React from 'react';
import { Page, Card, ErrorAlert, Button, P } from 'App/components';
import './TwoFactorAuthRegistrationPrompt.css';

type Props = {
  oneloginRedirect: ?string,
};
export const TwoFactorDeviceRegistrationPrompt = ({
  oneloginRedirect,
}: Props) => {
  if (!oneloginRedirect) {
    return (
      <ErrorAlert>
        <ErrorAlert.Copy>An unexpected error occurred.</ErrorAlert.Copy>
      </ErrorAlert>
    );
  }

  return (
    <Page>
      <Card title="Register a Device">
        <P>
          You are now being redirected to OneLogin to setup additional security
          factors
        </P>
        <P>
          Please sign-in to OneLogin using your Powerreviews username and
          password to register a two factor authentication device.
        </P>
        <Card.Divider />
        <div className="mt-8 text-center">
          <Button>
            {({ className }) => (
              <a
                href={oneloginRedirect}
                className={`${className} inline-flex items-center TwoFactorAuthRegistrationPrompt__redirect-button`}
              >
                <img
                  src="https://www.onelogin.com/assets/img/press/presskit/downloads/Onelogin_Logomark/Screen/png/Onelogin_Mark_white_RGB.png"
                  alt="onelogin"
                  className="w-6 h-6 mr-2"
                />
                <span>Continue to OneLogin</span>
              </a>
            )}
          </Button>
        </div>
      </Card>
    </Page>
  );
};

export default TwoFactorDeviceRegistrationPrompt;
