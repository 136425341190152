// @flow
import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Login from './screens/Login';
import { Page, Card, P } from './components';
import {
  loginRoute,
  resetPasswordRoute,
  createPasswordRoute,
  updatePasswordRoute,
} from './routes';
import AppState from './AppState';

const PasswordReset = React.lazy(() => import('./screens/PasswordReset'));
const PasswordCreate = React.lazy(() => import('./screens/PasswordCreate'));
const PasswordUpdate = React.lazy(() => import('./screens/PasswordUpdate'));

export default class App extends React.Component<void, { hasError: boolean }> {
  state = { hasError: false };

  componentDidCatch(err: Error) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError === true) {
      return (
        <Page>
          <Card title="Error">
            <P>An unexpected error occured, please refresh the page.</P>
            <P>If this error persists, contact Powerreviews support.</P>
          </Card>
        </Page>
      );
    }

    return (
      <AppState.Provider>
        <React.Suspense fallback={<Page />}>
          <Router>
            <Switch>
              <Route path={loginRoute} component={Login} />
              <Route
                exact
                path={resetPasswordRoute}
                component={PasswordReset}
              />
              <Route
                exact
                path={createPasswordRoute}
                component={PasswordCreate}
              />
              <Route
                exact
                path={updatePasswordRoute}
                component={PasswordUpdate}
              />
              <Redirect to={loginRoute} />
            </Switch>
          </Router>
        </React.Suspense>
      </AppState.Provider>
    );
  }
}

window.VERSION = process.env.REACT_APP_VERSION;
