// @flow
import * as React from 'react';
import { Page, Card, AppLink, Button } from 'App/components';
import { TextField } from '../components';
import { login } from './api';
import LoginError from './LoginError';
import { setCookie } from './auth-cookie';
import { resetPasswordRoute } from 'App/routes';
import TwoFactorAuthentication from './TwoFactorAuth';
import TwoFactorDeviceRegistrationComplete from './TwoFactorAuthRegistrationComplete';
import { type ContextRouter, Switch, Route, Redirect } from 'react-router-dom';
import ExpiredPassword from './ExpiredPassword';
import {
  loginRoute,
  mfaLoginRoute,
  mfaDeviceRegistrationCompletedRoute,
} from 'App/routes';
import AppState from 'App/AppState';
import {
  redirectDestination,
  redirect,
  processLoginResponse,
  type CompleteLoginResponse,
  type MFALoginResponse,
} from './utils';

type Props = {
  workflow: ?string,
  setMFAWorkflow: (*) => void,
  setExpiredPasswordWorkflow: (*) => void,
  continueTo: ?string,
} & ContextRouter;
type State = {
  username: string,
  password: string,
  fetchStatus: null | 'pending' | Error,
  attempts: number,
};

export class LoginForm extends React.Component<Props, State> {
  state = {
    username: '',
    password: '',
    fetchStatus: null,
    fetchPayload: null,
    attempts: 0,
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ fetchStatus: 'pending' });
    const { username, password } = this.state;
    this.setState(state => ({
      attempts: state.attempts + 1,
    }));

    try {
      const response = await login({ username, password });
      const payload = await processLoginResponse(response);

      if (payload.mfa) {
        this.beginMfa(payload);
      } else {
        this.finishLogin(payload);
      }
    } catch (err) {
      if (
        err.message === 'ExpiredPassword' &&
        err.body &&
        'profile_id' in err.body
      ) {
        this.props.setExpiredPasswordWorkflow({
          profileId: +err.body.profile_id,
        });
      }

      this.setState({
        fetchStatus: err,
      });
    }
  };

  beginMfa(payload: MFALoginResponse) {
    const { oneloginRedirect, devices, optional } = payload;
    this.props.setMFAWorkflow({
      mfaProps: {
        username: this.state.username,
        password: this.state.password,
        oneloginRedirect: oneloginRedirect,
        devices: devices,
        optional: optional,
      },
    });
  }

  finishLogin(payload: CompleteLoginResponse) {
    const { token, expiration, homeHref } = payload;
    const dest = redirectDestination(homeHref, this.props.continueTo);

    setCookie(token, expiration);
    // this.props.clearAppState();
    redirect(dest);
  }

  render() {
    const { fetchStatus, username, password, attempts } = this.state;

    if (this.props.workflow === 'ExpiredPassword') {
      return <ExpiredPassword />;
    } else if (this.props.workflow === 'MFA' && username && password) {
      return <Redirect to={mfaLoginRoute} />;
    }

    return (
      <Page title="Login">
        <form method="post" onSubmit={this.handleSubmit}>
          <Card title="Log in to continue">
            {fetchStatus instanceof Error && (
              <LoginError error={fetchStatus} attempts={attempts} />
            )}
            <TextField
              label="Email Address"
              name="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <div className="mt-8 text-center">
              <Button
                label="Log in"
                type="submit"
                onClick={this.handleSubmit}
                disabled={fetchStatus === 'pending'}
              />
            </div>
          </Card>
          <div className="text-center my-6">
            <AppLink to={resetPasswordRoute}>Forgot Your Password?</AppLink>
          </div>
        </form>
      </Page>
    );
  }
}

export default function LoginRouter(props: *) {
  return (
    <AppState.Consumer>
      {({
        appState,
        appState: { workflow = null, continueTo },
        setAppState,
        clearAppState,
        setMFAWorkflow,
        setExpiredPasswordWorkflow,
      } = {}) => {
        return (
          <Switch>
            <Route
              exact
              path={loginRoute}
              render={() => (
                <LoginForm
                  setMFAWorkflow={setMFAWorkflow}
                  setExpiredPasswordWorkflow={setExpiredPasswordWorkflow}
                  workflow={workflow}
                  continueTo={continueTo}
                />
              )}
            />
            <Route
              exact
              path={mfaLoginRoute}
              component={TwoFactorAuthentication}
            />
            <Route
              exact
              path={mfaDeviceRegistrationCompletedRoute}
              component={TwoFactorDeviceRegistrationComplete}
            />
            <Redirect
              to={{
                path: loginRoute,
                search: props.location.search,
              }}
            />
          </Switch>
        );
      }}
    </AppState.Consumer>
  );
}
