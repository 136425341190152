// @flow
import ExtendableError from 'es6-error';
import find from 'array.prototype.find';

export class HttpError extends ExtendableError {
  status: ?number;
  message: string;
  body: ?{ [string]: mixed };

  constructor(message: string, status: ?number, body?: ?{ [string]: mixed }) {
    super(message);
    this.message = message;
    this.status = status;
    this.body = body;
  }
}

const ERROR_KEY_REGEX = /error/i;

export class ClientError extends HttpError {
  static createFromObject(obj: *, status: number) {
    const objKeys = Object.keys(obj);
    const errorKey = find(objKeys, k => ERROR_KEY_REGEX.test(k));
    const message = errorKey ? obj[errorKey] : 'ClientError';

    return new ClientError(message, status, obj);
  }
}

export class ServerError extends HttpError {}

export class NetworkingError extends ExtendableError {
  constructor(message: string = 'Connection Error') {
    super(message);
  }
}

export type FetchError =
  | HttpError
  | ClientError
  | ServerError
  | NetworkingError;

export type FetchErrorResponseBody = {
  error_message: string,
};

export default {
  isHttpError(err: any): boolean {
    return err instanceof HttpError;
  },
  isClientError(err: mixed): boolean {
    return err instanceof ClientError;
  },
  isServerError(err: mixed): boolean {
    return err instanceof ServerError;
  },
  isNetworkingError(err: mixed): boolean {
    return err instanceof NetworkingError;
  },
};
