// @flow
import * as React from 'react';
import { Page, Card } from 'App/components';
import { Button, ButtonMuted, TextField, Label } from 'App/screens/components';
import TwoFactorDeviceRegistrationPrompt from './TwoFactorAuthRegistrationPrompt';
import AppState, { type MFAProps } from 'App/AppState';
import { mfaLogin } from './api';
import { redirectDestination, redirect, processLoginResponse } from './utils';
import { setCookie } from './auth-cookie';
import ExpiredPassword from './ExpiredPassword';
import LoginError from './LoginError';

type Props = MFAProps & {
  setExpiredPasswordWorkflow: (*) => void,
  workflow: ?string,
  continueTo: ?string,
};

type State = {
  deviceId: string,
  otp: ?string,
  skip: boolean,
  fetchStatus: null | 'pending' | Error,
};

export class TwoFactorAuthentication extends React.Component<Props, State> {
  state = {
    deviceId:
      this.props.devices.length === 1 ? this.props.devices[0].deviceId : '',
    otp: '',
    skip: false,
    fetchStatus: null,
  };

  handleChange = (
    e: SyntheticEvent<HTMLInputElement> | SyntheticEvent<HTMLSelectElement>
  ) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  handleSubmit = async (e?: SyntheticEvent<HTMLFormElement>) => {
    e && e.preventDefault();

    const { deviceId, otp, skip } = this.state;
    const { username, password } = this.props;
    const credentialsAreInvalid = !deviceId || !otp;

    // do nothing if credentials are not valid and we are not skipping
    if (!skip && credentialsAreInvalid) {
      this.setState({
        fetchStatus: new Error('Invalid email or password'),
      });
      return;
    }

    this.setState({ fetchStatus: 'pending' });

    try {
      const response = await mfaLogin({
        username,
        password,
        deviceId,
        otp,
        skip,
      });

      const payload = await processLoginResponse(response);
      if (payload.mfa === true) {
        throw new Error('Incomplete Authentication');
      }
      const { token, expiration, homeHref } = payload;
      const dest = redirectDestination(homeHref, this.props.continueTo);

      setCookie(token, expiration);
      redirect(dest);
    } catch (err) {
      if (
        err.message === 'ExpiredPassword' &&
        err.body &&
        'profile_id' in err.body
      ) {
        this.props.setExpiredPasswordWorkflow({
          profileId: +err.body.profile_id,
        });
      }

      this.setState({ fetchStatus: err });
    }
  };

  handleSkip = () => {
    this.setState({ skip: true }, this.handleSubmit);
  };

  render() {
    const { optional, devices, oneloginRedirect } = this.props;

    if (this.props.workflow === 'ExpiredPassword') {
      return <ExpiredPassword />;
    }

    if (devices.length === 0) {
      return (
        <TwoFactorDeviceRegistrationPrompt
          oneloginRedirect={oneloginRedirect}
        />
      );
    }
    const { deviceId, otp, fetchStatus } = this.state;
    const canSubmit = deviceId && otp;

    return (
      <Page>
        <form method="post" onSubmit={this.handleSubmit}>
          <Card title="Two Factor Authentication">
            {fetchStatus instanceof Error && <LoginError error={fetchStatus} />}
            <div className="form-row">
              <Label htmlFor="device">Device</Label>
              <div className="relative">
                <select
                  className="form-control"
                  id="device"
                  value={deviceId}
                  name={'deviceId'}
                  onChange={this.handleChange}
                  disabled={devices.length === 1}
                >
                  {devices.map(device => (
                    <option value={device.deviceId} key={device.deviceId}>
                      {device.deviceType}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    className="fill-current h-4 w-4"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <TextField
              label="Passcode"
              name="otp"
              value={otp}
              onChange={this.handleChange}
              autoComplete="off"
            />
            <div className="mt-8 flex justify-center">
              {optional === true && (
                <ButtonMuted className="mr-2" onClick={this.handleSkip}>
                  Skip
                </ButtonMuted>
              )}
              <Button
                label="Continue"
                disabled={canSubmit === false}
                type="submit"
              />
            </div>
          </Card>
        </form>
      </Page>
    );
  }
}

export default function C(props: Props) {
  return (
    <AppState.Consumer>
      {({ appState, setExpiredPasswordWorkflow, clearAppState } = {}) => {
        return appState.mfaProps != null ? (
          <TwoFactorAuthentication
            {...appState.mfaProps}
            continueTo={appState.continueTo}
            workflow={appState.workflow}
            setExpiredPasswordWorkflow={setExpiredPasswordWorkflow}
          />
        ) : null;
      }}
    </AppState.Consumer>
  );
}
