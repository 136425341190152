// @flow
import * as React from 'react';
import { Page, Card, P } from 'App/components';
import { Button } from 'App/screens/components';
import { Link } from 'react-router-dom';
import { updatePasswordRoute } from 'App/routes';

const ExpiredPassword = () => (
  <Page>
    <Card title="Password Expired">
      <P>
        Your password has expired. You will need to create a new password to
        continue.
      </P>
      <hr className="border-b border-gray-lighter -mx-6" />
      <div className="mt-8 text-center">
        <Button kind="primary">
          {({ className }) => (
            <Link to={updatePasswordRoute} className={className}>
              Create a new Password
            </Link>
          )}
        </Button>
      </div>
    </Card>
  </Page>
);

export default ExpiredPassword;
