// @flow
import * as React from 'react';
import validator from './validator';
import classnames from 'classnames';
import { InfoIcon, SuccessIcon, ErrorIcon } from './status-icons';

type Value = {
  newPassword: string,
  confirmNewPassword: string,
  validationResults: *,
};

type Props = {
  value: Value,
  onChange: Value => any,
};

type State = {
  previewNewPassword: boolean,
  previewConfirmNewPassword: boolean,
  newPasswordFocused: boolean,
  confirmPasswordFocused: boolean,
};

export type NewPasswordState = Value;

export class NewPasswordFieldset extends React.Component<Props, State> {
  state = {
    previewNewPassword: false,
    previewConfirmNewPassword: false,
    newPasswordFocused: false,
    confirmPasswordFocused: false,
  };

  static getInitialState() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      validationResults: validator(''),
    };
  }

  static canSubmit(value: Value): boolean {
    const { newPassword, confirmNewPassword, validationResults } = value;
    const fails = validationResults.filter(r => r.result === false);

    if (fails.length > 0) return false;
    if (newPassword !== confirmNewPassword) return false;

    return true;
  }

  static combineServerValidationResults(
    value: Value,
    serverValidationResults: string
  ): Value {
    return {
      ...value,
      validationResults: validator(
        value.newPassword,
        new Set([serverValidationResults])
      ),
    };
  }

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.name;
    const inputValue = e.currentTarget.value;
    const validationResults =
      inputName === 'newPassword'
        ? validator(inputValue)
        : this.props.value.validationResults;

    this.props.onChange({
      ...this.props.value,
      [inputName]: inputValue,
      validationResults,
    });
  };

  renderStatusIcon(flag: ?boolean, passwordHasValue: boolean) {
    if (passwordHasValue) {
      if (flag === true) {
        return <SuccessIcon />;
      } else if (flag === false) {
        return <ErrorIcon />;
      }
    }

    return <InfoIcon />;
  }

  render() {
    const {
      value: { newPassword, confirmNewPassword, validationResults },
    } = this.props;
    const {
      previewNewPassword,
      previewConfirmNewPassword,
      newPasswordFocused,
      confirmPasswordFocused,
    } = this.state;
    const fails = validationResults.filter(r => r.result === false);

    // show error when user is typing and inputs wrong character
    // show error when input loses focus
    let showConfirmationWarning = false;
    if (confirmNewPassword && confirmNewPassword !== newPassword) {
      if (confirmPasswordFocused) {
        showConfirmationWarning = newPassword.indexOf(confirmNewPassword) !== 0;
      } else {
        showConfirmationWarning = true;
      }
    }

    return (
      <fieldset>
        <div className="form-row mt-6">
          <label htmlFor="np1" className="form-label">
            New Password
          </label>
          <div className="relative">
            <input
              id="np1"
              type={previewNewPassword ? 'text' : 'password'}
              autoComplete="new-password"
              className={classnames('form-control bg-animate', {
                'form-control--error':
                  !newPasswordFocused && newPassword && fails.length > 0,
                'form-control--success':
                  !newPasswordFocused && newPassword && fails.length === 0,
              })}
              name="newPassword"
              value={newPassword}
              onChange={this.handleChange}
              onFocus={() => this.setState({ newPasswordFocused: true })}
              onBlur={() => this.setState({ newPasswordFocused: false })}
            />
          </div>
        </div>
        <ul className="bg-near-white-blue-tint list-reset my-6 p-4 text-sm text-gray-darkest">
          {validationResults.map(rule => (
            <li
              key={rule.id}
              className="flex leading-tight mb-2 items-baseline"
              data-testid={rule.result === false ? rule.id : undefined}
            >
              <span className="block flex-none w-2 mr-2 pb-1">
                {this.renderStatusIcon(rule.result, Boolean(newPassword))}
              </span>
              {rule.message}
            </li>
          ))}
        </ul>
        <div
          className={classnames('form-row', {
            'opacity-50 pointer-events-none cursor-not-allowed':
              fails.length > 0,
          })}
        >
          <label htmlFor="confirm" className="form-label">
            Confirm New Password
          </label>
          <div className="relative">
            <input
              id="confirm"
              type={previewConfirmNewPassword ? 'text' : 'password'}
              autoComplete="new-password"
              className={classnames('form-control bg-animate', {
                'form-control--error': showConfirmationWarning,
                'form-control--success':
                  confirmNewPassword && confirmNewPassword === newPassword,
              })}
              name="confirmNewPassword"
              value={confirmNewPassword}
              disabled={fails.length > 0}
              onChange={this.handleChange}
              onFocus={() => this.setState({ confirmPasswordFocused: true })}
              onBlur={() => this.setState({ confirmPasswordFocused: false })}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}

export default NewPasswordFieldset;
