// @flow
import * as React from 'react';

type Props = {
  name: string,
  label: string,
  type?: string,
  value: ?string,
  onChange?: Function,
  autoComplete?: 'on' | 'off',
};

export const Label = (props: { className?: string }) => (
  <label {...props} className="form-label" />
);

const TextField = ({
  name,
  type = 'text',
  label,
  value,
  autoComplete,
  onChange,
}: Props) => (
  <div className="form-row">
    <Label htmlFor={name}>{label}</Label>
    <input
      type={type}
      name={name}
      id={name}
      className="form-control"
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
    />
  </div>
);

export default TextField;
