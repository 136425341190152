import * as React from 'react';

type CardProps = {
  title?: string,
  children: React.Node,
};

const CardHeader = (props: *) => <div className="p-6 ">{props.children}</div>;
const CardBody = (props: *) => <div className="p-6">{props.children}</div>;
const CardFooter = (props: *) => <div className="p-6">{props.children}</div>;

const Card = (props: CardProps) => {
  return (
    <div className="bg-white block rounded shadow">
      <div className="p-6">
        {props.title && (
          <h1 className="mt-2 mb-6 text-center font-brandon text-2xl">
            {props.title}
          </h1>
        )}
        {props.children}
      </div>
    </div>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

Card.Divider = () => <hr className="border-b border-gray-lighter -mx-6" />;

export default Card;
